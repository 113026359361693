import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Img from "gatsby-image"

import Hero from "../components/sections/hero/hero"

import Layout from "../components/theme/theme"

import "../components/sections/section/section.css"
import "../components/sections/section/bullet-list.css"

const IndexPage = props => {
  const heroImages = [
    props.data.mobileHero.childImageSharp.fluid,
    {
      ...props.data.desktopHero.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <Helmet title="Single point of contact throughout the process" />

      <Hero image={heroImages} textcolor="dark" subtitle="Get to know the process" title="Single point of contact for all your requirements" />

      <section className="sec-Section">
        <div className="sec-Section_Inner lyt-Container">
          <div className="sec-Section_Header">
            <div className="sec-Section_Inset">
              <h3 className="sec-Section_SubTitle">
                We make it simple
              </h3>

              <h2 className="sec-Section_Title">
                Your single point of contact
              </h2>
            </div>
          </div>

          <div className="sec-Section_Body">
            <div className="sec-Section_Inset">
              <p>CBW Limited aim to provide a single point of contact for all your requirements. We strive to provide a fully managed product, from order recept through to home owner satisfaction receipt.</p>
              
              <p>Directly employing our staff ensures total control, combined with an experienced management team and dedicated office support, enabling all work to be undertaken in a swift professional manner.</p>

              <Img fluid={props.data.designImage.childImageSharp.fluid} className="sec-Section_WideImage" />
              
              <p>We ensure that prompt attendance, combined with well presented individuals, provide the correct initial appearance, at the homeowners' doorstep. Thorough protection from door step to works area and considerate attitude to the home environment continues before works commence. We utilise all available material and technologies to reduce drying times, avoiding unnecessary delays and consequential return visits. Works are planned and undertaken in a methodical manner with specific materials sourced prior to attendance, again minimising site time.</p>

              <h3 className="sec-Section_SubHeading">
                The finishing touches
              </h3>
              
              <p>Upon completion of each working day all protections are uplifted and removed from site and the working area thoroughly cleansed, we ensure each of our vehicles hold a full cleaning kit.</p>
              
              <p>At the final completion of works all working areas are inspected to ensure home owner satisfaction and all furniture replaced to its original position.</p>
              
              <p>Our operatives are fully experienced working in the 'customer care' environment, they are your representative whilst in the property and are aware of questions and requests often asked by the homeowner, all of which are all declined and referred back to yourself for final comment.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    desktopHero: file(relativePath: { eq: "process-hero.jpg" }) {
      ...fluidImage
    }
    mobileHero: file(relativePath: { eq: "process-hero-mobile.jpg" }) {
      ...fluidImage
    }
    designImage: file(relativePath: { eq: "process-sub-image.jpg" }) {
      ...fluidImage
    }
  }
`;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2880) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
